import React, { useState, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { navigate, Link } from 'gatsby'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  VStack,
} from '@chakra-ui/react'

import { useAuth } from 'contexts/AuthContext'
import { countryNames, statesPerCountry } from '../../utils/country_states'
import ApiFetch from '../../utils/api'
import { FormSection, FormElement } from 'starterComponents'
import { Container } from 'starterUiComponents'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const BRAINTREE_TOKENIZATION_KEY = process.env.GATSBY_BRAINTREE_TOKENIZATION_KEY

let dropin = null

if (typeof window !== `undefined`) {
  dropin = require('braintree-web-drop-in')
}

// TODO: Improve this to use the WP Admin password
const ACCESS_PROTECTED_PASSWORD = 'atsgaccessrecyclers'

// ñapa horrenda para mantener estado del cuadro de dialogo
let _global_has_access = false
let dropinInstance = null

/**
 * This page should be password protected and "removed from google search". This last point should be managed by Yoast SEO Advanced settings
 *
 * @returns
 */
const SalvageYardSignupPage = () => {
  const { handleSubmit, register, watch, control, errors } = useForm({
    defaultValues: {
      address_1: '',
      address_2: '',
      address_3: '',
      city: '',
      firstName: '',
      lastName: '',
      country: 'United States',
      state: '',
      email: '',
      password: '',
      phone: '',
      shop_name: '',
      bulletins: 'no',
      hearedFromUs: undefined,
      zip: '',
    },
  })

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()

  const defaultPageAccessStatus = _global_has_access ? 'approved' : 'pending'
  const [pageAccessStatus, setPageAccessStatus] = useState(
    defaultPageAccessStatus
  ) // "appproved", "rejected"
  const { executeRecaptcha } = useGoogleReCaptcha()

  const countryWatch = watch('country')
  const dropinRef = React.useRef(null)

  const handleReCaptchaVerify = useCallback(
    async (event) => {
      event.preventDefault()
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        setError(`Unexpected error, please retry later`)
        return
      }
      try {
        // TODO: check actions names
        const token = await executeRecaptcha('signup')
        return token
      } catch (error) {
        console.log('error: ', error)
        setError(`Unexpected error, please retry later`)
        return
      }
    },
    [executeRecaptcha]
  )

  const onSubmit = async (data, e) => {
    const recaptchaToken = await handleReCaptchaVerify(e)
    if (!recaptchaToken) {
      setError(`Unexpected error, please retry later`)
      return
    }

    const { email, password } = data
    data.grandTotal = 595
    data.contactName = `${data.firstName} ${data.lastName};`
    data.recaptchaToken = recaptchaToken

    try {
      setError('')
      setLoading(true)
      const payload = await dropinInstance.requestPaymentMethod()
      data.paymentMethodNonce = payload.nonce
      data.bulletins = false
      const response = await ApiFetch({
        method: 'POST',
        path: `/salvage`,
        body: data,
      })
      // The email address is already in use by another account
      if (response.error) {
        if (response.code === 'auth/email-already-exists') {
          // setError(`The email address is already in use by another account`)
          setError(`auth/email-already-exists`)
        } else {
          setError(`Failed to create an account: ${response.error}`)
        }
        alertRef?.current && alertRef.current.scrollIntoView()
      } else {
        await login(email, password)
        navigate('/dashboard')
      }
      setLoading(false)
    } catch (e) {
      console.error('Failed to create an account: ', e.message)
      setError(`Failed to create an account: ${e.message}`)
    }
    setLoading(false)
  }

  const onErrors = (errors) => console.error('errors', errors)

  const alertRef = React.useRef()

  const createDropinInstance = React.useCallback(() => {
    dropin
      .create({
        authorization: BRAINTREE_TOKENIZATION_KEY,
        container: '#dropin-container',
      })
      .then((res) => {
        dropinInstance = res
      })
      .catch((err) => {
        console.log('ERROR: ', err)
      })
  }, [])

  React.useEffect(() => {
    const createDropinInstance = () => {
      dropin
        .create({
          authorization: BRAINTREE_TOKENIZATION_KEY,
          container: '#dropin-container',
        })
        .then((res) => {
          dropinInstance = res
        })
        .catch((err) => {
          console.log('ERROR: ', err)
        })
    }
    if (dropin && dropinRef?.current?.children?.length < 1) {
      createDropinInstance()
    }
  }, [createDropinInstance])

  const parseError = (error) => {
    if (error === 'auth/email-already-exists') {
      error = 'The email address is already in use by another account'
      return (
        <span>
          <span style={{ color: 'white' }}>{error}</span>
          <Link
            to="/sign-in"
            className="relative text-base uppercase font-heading text-white ml-2"
          >
            . GO TO LOGIN?
          </Link>
        </span>
      )
    }
    return <span style={{ color: 'white' }}>{error}</span>
  }

  if (pageAccessStatus === 'pending') {
    if (typeof window !== `undefined`) {
      const password = window.prompt(
        'Please provide your password to access this site'
      )
      if (password === ACCESS_PROTECTED_PASSWORD) {
        setPageAccessStatus('accepted')
        _global_has_access = true
      } else {
        setPageAccessStatus('pending')
        _global_has_access = false
      }
    }
  }

  if (pageAccessStatus === 'rejected') {
    return <h1>Please add credentials first (wrong)</h1>
  } else if (pageAccessStatus === 'pending') {
    return <h1>Please add credentials first</h1>
  } else {
    return (
      <Container>
        {error && (
          <Alert ref={alertRef} status="error" style={{ marginTop: '1em' }}>
            <AlertIcon />
            {parseError(error)}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          {/* Account Information */}
          <FormSection bgColor="lightBlue" title="Account Information">
            <FormElement
              id="firstName"
              label="First Name:"
              type="input"
              required
              isInvalid={Boolean(errors.firstName)}
            >
              <Input
                placeholder="Your first name"
                ref={register({ required: true })}
                name="firstName"
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.firstName?.message ||
                  `firstName is ${errors?.firstName?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="lastName"
              label="Last Name:"
              type="input"
              required
              isInvalid={Boolean(errors.lastName)}
            >
              <Input
                placeholder="Your last name"
                ref={register({ required: true })}
                name="lastName"
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.lastName?.message ||
                  `lastName is ${errors?.lastName?.type}`}
              </FormErrorMessage>
            </FormElement>

            <FormElement
              id="password"
              label="Choose a Password:"
              type="input"
              required
              isInvalid={Boolean(errors.password)}
            >
              <Input
                type="password"
                placeholder="password"
                ref={register({ required: true })}
                name="password"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.password?.message ||
                  `password is ${errors?.password?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="shop"
              label="Shop Name:"
              type="input"
              isInvalid={Boolean(errors.password)}
            >
              <Input placeholder="shop name" name="shop_name" ref={register} />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.shop_name?.message ||
                  `Shop Name is ${errors?.shop_name?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="address1"
              label="Address 1:"
              sx={{ label: { mt: 3 } }}
            >
              <Input
                placeholder="Line address 1"
                name="address_1"
                ref={register}
              />
            </FormElement>
            <FormElement
              id="address2"
              label="Address 2:"
              sx={{ label: { mt: 3 } }}
            >
              <Input
                placeholder="Line address 2"
                name="address_2"
                ref={register}
              />
            </FormElement>
            <FormElement
              id="address3"
              label="Address 3:"
              sx={{ label: { mt: 3 } }}
            >
              <Input
                placeholder="Line address 3"
                name="address_3"
                ref={register}
              />
            </FormElement>
            <FormElement id="city" label="City:" type="input" required>
              <Input
                placeholder="City"
                ref={register({ required: true })}
                name="city"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.city?.message || `city is ${errors?.city?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="country"
              label="Country:"
              type="input"
              required
              isInvalid={Boolean(errors.country)}
            >
              <Select
                placeholder="Country"
                ref={register({ required: true })}
                name="country"
              >
                {countryNames.map((country) => (
                  <option value={country} key={country}>
                    {country}
                  </option>
                ))}
              </Select>
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.country?.message ||
                  `country is ${errors?.country?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="state"
              label="State:"
              type="input"
              required
              isInvalid={Boolean(errors.state)}
            >
              <Select
                placeholder="State"
                ref={register({ required: true })}
                name="state"
                defaultValue={''}
              >
                {statesPerCountry[countryWatch] &&
                  statesPerCountry[countryWatch].map((state) => (
                    // I mixed label/values on the json :D
                    <option value={state.label} key={state.label}>
                      {state.value}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.state?.message || `state is ${errors?.state?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="postal-code"
              label="Zip or Postal Code:"
              type="input"
              required
              isInvalid={Boolean(errors.zip)}
            >
              <Input
                placeholder="Zip or Postal Code"
                ref={register({ required: true })}
                name="zip"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.zip?.message || `zip code is ${errors?.zip?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="phone"
              label="Phone:"
              type="input"
              required
              isInvalid={Boolean(errors.phone)}
            >
              <Input
                type="tel"
                placeholder="+X (XXX) XXX-XXXX"
                ref={register({ required: true })}
                name="phone"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.phone?.message || `phone is ${errors?.phone?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="email"
              label="Your Email:"
              type="input"
              required
              isInvalid={Boolean(errors.email)}
            >
              <Input
                placeholder="your email"
                type="email"
                ref={register({ required: true })}
                name="email"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {errors?.email?.message || `email is ${errors?.email?.type}`}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="where-did-you-hear"
              label="How did you hear about us?"
            >
              <Controller
                control={control}
                name="hearedFromUs"
                as={
                  <RadioGroup ref={register} name="hearedFromUs">
                    <VStack align="flex-start" spacing={4}>
                      <Radio value="facebook">Facebook </Radio>
                      <Radio value="youtube">YouTube</Radio>
                      <Radio value="google">Google</Radio>å
                      <Radio value="referral">Referral</Radio>
                      <Radio value="other">Other</Radio>
                      {/* <Radio value="other">
                      <Input placeholder="other" height={10} ref={register} name="hearedFromUsOther" />
                    </Radio> */}
                    </VStack>
                  </RadioGroup>
                }
              ></Controller>
            </FormElement>
          </FormSection>

          {/* Payment info*/}

          <FormSection bgColor="lightBlue" title="Payment Information">
            <div id="dropin-container" ref={dropinRef}></div>
            <Flex className="justify-end w-full">
              <VStack>
                <Box className="font-bold font-heading text-l">
                  Grand Total:{' '}
                  <span
                    style={{ minWidth: '180px' }}
                    className="inline-block text-right"
                  >
                    $595 / month
                  </span>
                </Box>
              </VStack>
            </Flex>
            <Flex className="justify-start w-full">
              <VStack>
                <Box
                  className="mt-4"
                  style={{
                    fontSize: '0.8rem',
                    lineHeight: '1rem',
                  }}
                >
                  <span>
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a href="https://policies.google.com/privacy">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href="https://policies.google.com/terms">
                      Terms of Service
                    </a>{' '}
                    apply.
                  </span>
                </Box>
              </VStack>
            </Flex>
          </FormSection>
          <Flex justify={{ base: 'center', sm: 'flex-end' }}>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={5}
              sx={{ button: { width: 200 } }}
            >
              <Button variant="outlineDark">Reset</Button>
              <Button
                variant="lightBlue"
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
              >
                Submit{loading && 'ting'}
              </Button>
            </Stack>
          </Flex>
        </form>
      </Container>
    )
  }
}

export default SalvageYardSignupPage
